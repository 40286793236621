import React from "react";
import styled, { css } from "styled-components";

const MAX_WIDTH = 1080;

const WrapperOuter = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ stretch }) =>
    stretch &&
    css`
      height: 100%;
    `}
`;

const WrapperInner = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin: 0 2rem;
  ${({ stretch }) =>
    stretch &&
    css`
      height: 100%;
    `}
`;

const Wrapper = ({ children, stretch, maxWidth = MAX_WIDTH }) => (
  <WrapperOuter stretch={stretch}>
    <WrapperInner maxWidth={maxWidth} stretch={stretch}>
      {children}
    </WrapperInner>
  </WrapperOuter>
);

export default Wrapper;
