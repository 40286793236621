/**
 * Interface for analytics.
 */

export const ready = (callback) => {
  if (window.analytics) {
    window.analytics.ready(callback);
  }
};

export const identify = (userId, traits) => {
  if (window.analytics) {
    window.analytics.identify(userId, traits);
  }
};

export const track = (eventName, properties) => {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};
