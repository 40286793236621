import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Media from "./media";
import Logo from "./Logo";

import useScrollPosition from "./hooks/useScrollPosition";
import { NAVBAR_HEIGHT_DESKTOP } from "../constants";
import { track } from "../utils/analytics";

const HeaderContainer = styled.header`
  position: absolute;
  top: 40px;

  ${Media.Tablet`
    position: sticky;
    top: 0rem;
    height: ${NAVBAR_HEIGHT_DESKTOP};
  `}

  z-index: 500;
  /* top: 0; */
  left: 0;
  width: 100vw;
  padding: 0.6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fill }) =>
    fill &&
    css`
      background: black;
      position: relative;
    `};
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: calc(1080px + 4rem);
  padding: 0 2rem;
  width: 100%;

  ${Media.Tablet`
    box-shadow: 0 7px 7px 0px
    rgba(20, 20, 20, ${({ lifted }) => (lifted ? 0.05 : 0)});
    background: rgba(255, 255, 255, ${({ lifted }) => (lifted ? 0.6 : 0)});
    backdrop-filter: blur(${({ lifted }) => (lifted ? 0.6 : 0)}rem);
  `}

  transition: filter 0.4s, background-color 0.4s, color 0.4s, box-shadow 0.4s;

  border-radius: 0.4rem;
`;

const NavContainer = styled.nav`
  /* width: calc(100% + 2rem); */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1080px;
  width: 100%;
  /* margin: 0 2rem; */

  /* box-shadow: 0 7px 7px 0px
    rgba(20, 20, 20, ${({ lifted }) => (lifted ? 0.05 : 0)});
  background: rgba(255, 255, 255, ${({ lifted }) => (lifted ? 0.6 : 0)});
  backdrop-filter: blur(${({ lifted }) => (lifted ? 0.6 : 0)}rem); */

  a {
    color: ${({ lifted }) => (lifted ? "#444444" : "white")};
  }

  padding: 0.6rem 0;
  border-radius: 0.4rem;

  transition: filter 0.4s, background-color 0.4s, color 0.4s, box-shadow 0.4s;
`;

const Login = styled(Link)`
  text-decoration: none;
  margin: 0;
  padding: 0;
  /* margin-left: auto; */
  font-weight: 700;
  font-size: 1rem;
  padding: 0.4rem 1.2rem;
  border-radius: 0.2rem;
  /* background-color: rgba(255, 255, 255, 0.33);
   */
  background-color: #dcf3eb;
  /* color: #428e79; */
  color: #444;

  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
    border-color: #ccc;
  }

  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const NavLink = styled(Link)`
  color: white;
  font-size: 0.9rem;
  margin-right: 2rem;
  font-weight: 600;
  text-decoration: none;

  display: none;
  ${Media.Tablet`
    display: block;
  `}
`;

const Header = ({ siteTitle, fill }) => {
  const scrollPosition = useScrollPosition();
  const lifted = scrollPosition > 40;
  return (
    <HeaderContainer fill={fill}>
      <NavWrapper lifted={lifted}>
        <NavContainer lifted={lifted}>
          <Logo color={lifted ? "#005A5C" : "#ffffff"} />
          <NavLink to="/#section_product">Product</NavLink>
          <NavLink to="/#section_impact">Impact</NavLink>
          <NavLink to="/#section_customers">Customers</NavLink>
          <NavLink to="/#section_about">About</NavLink>
          <Login
            style={{ color: "#222" }}
            to="/#section_get_started"
            onClick={() => {
              track("Landing Page Sales Intent Interaction", {
                label: "Get started button (nav)"
              });
            }}
          >
            Get started
          </Login>
        </NavContainer>
      </NavWrapper>
    </HeaderContainer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
