import { css } from "styled-components"

const Device = {
  Mobile: 576,
  Tablet: 768,
  Laptop: 1024,
  Desktop: 1200,
}

export default Object.keys(Device).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${Device[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
