import React, { useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import styled from "styled-components";

import { track } from "../utils/analytics";

let store = null;

const HiringBannerStyle = styled.div`
  background: #dcf3eb;
  color: #005a5c;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 700;
  cursor: pointer;
  &: hover {
    background-color: #b9f2de;
  }
  transition: background-color 0.2s, color 0.2s;

  .message {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CloseButton = styled.div`
  height: 1.4rem;
  width: 1.4rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

const HeaderBanner = ({
  bannerKey,
  url,
  isExternal = false,
  enableCloseButton = false,
  message,
}) => {
  const [show, setShow] = useState(!enableCloseButton);

  useEffect(() => {
    if (enableCloseButton) {
      import("storejs")
        .then(({ default: storejs }) => {
          store = storejs;
          const shouldShow = !store.get(bannerKey);
          setShow(shouldShow);
        })
        .catch((err) => console.log(err));
    }
  }, [bannerKey, enableCloseButton]);

  const hideBanner = () => {
    if (store && enableCloseButton) {
      store.set(bannerKey, true);
      setShow(false);
    }
  };

  return show ? (
    <HiringBannerStyle
      onClick={() => {
        if (isExternal) {
          if (typeof window !== "undefined") {
            window.open(url, "_blank");
          }
        } else {
          navigate(url);
        }
        track(`Header Banner (${bannerKey}) Clicked`);
        hideBanner();
      }}
    >
      <span className="message">{message}</span>
      {enableCloseButton && (
        <CloseButton
          onClick={(event) => {
            hideBanner();
            event.stopPropagation();
          }}
        >
          &times;
        </CloseButton>
      )}
    </HiringBannerStyle>
  ) : null;
};

export default HeaderBanner;
