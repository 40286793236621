import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { SocialIcon } from "react-social-icons";

import Media from "./media";
import Logo from "./Logo";
import Wrapper from "./layout/wrapper";

const FooterSection = styled.footer`
  /* border-top: 8px solid black; */
  /* background-image: linear-gradient(
    to right top,
    #0a7871,
    #008979,
    #009a7e,
    #00ab7f,
    #0bbc7e
  ); */
  background: #005a5c;
  color: white;
  padding: 8rem 0 4rem;
  font-size: 0.8rem;
`;
const SocialIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
`;

const FooterEnclosure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${Media.Laptop`
  flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  `}
`;

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  margin-top: 1rem;

  img {
    height: 1.4rem;
    margin-bottom: 1rem;
  }

  ${Media.Laptop`
    margin-top: 0;
  `}

  ${({ center }) =>
    center &&
    css`
      ${Media.Laptop`
    align-items: center;
    `}

      span {
        font-weight: 600;
      }
    `}

  ${({ right }) =>
    right &&
    css`
      ${Media.Laptop`
    align-items: flex-end;
    `}
    `}

    p {
    margin: 0;
    padding: 0;
    margin-top: 0.6rem;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ExternalLink = styled.a`
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background: rgba(20, 20, 20, 0.8);
  font-size: 1.1rem;
  margin-bottom: 1rem;
`;

const InternalLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  margin: 0.3rem 0;
`;

const ExternalLinkNormal = styled.a`
  color: white;
  text-decoration: none;
  font-weight: 600;
  margin: 0.3rem 0;
`;

const Footer = () => (
  <FooterSection>
    <Wrapper>
      <FooterEnclosure>
        <Aside>
          <Logo color="#ffffff" style={{ marginBottom: "1rem" }} />
          <SocialIcons>
            <SocialIconWrapper>
              <SocialIcon
                url="https://instagram.com/Verdn.Impact"
                bgColor="#DCF3EB"
                fgColor="#005A5C"
                style={{ height: "100%", width: "100%" }}
              />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <SocialIcon
                url="https://twitter.com/VerdnImpact"
                bgColor="#DCF3EB"
                fgColor="#005A5C"
                style={{ height: "100%", width: "100%" }}
              />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <SocialIcon
                url="https://linkedin.com/company/Verdn"
                bgColor="#DCF3EB"
                fgColor="#005A5C"
                style={{ height: "100%", width: "100%" }}
              />
            </SocialIconWrapper>
          </SocialIcons>
          <p>&copy; {new Date().getFullYear()} Verdn Ltd</p>
        </Aside>
        <Aside center>
          <ExternalLink target="_blank" href="https://verdn.substack.com">
            Read Our Blog
          </ExternalLink>
          <p>
            Questions: <span>support@verdn.com</span>
          </p>
          <p>
            Investors &amp; Press: <span>contact@verdn.com</span>
          </p>
        </Aside>
        <Aside right>
          <InternalLink to="/clients">Our Clients</InternalLink>
          <InternalLink to="/privacy-policy">Privacy Policy</InternalLink>
          <ExternalLinkNormal
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ycombinator.com/companies/verdn/jobs"
          >
            Work at Verdn
          </ExternalLinkNormal>
          <p>Made in 🇬🇧</p>
        </Aside>
      </FooterEnclosure>
    </Wrapper>
  </FooterSection>
);

export default Footer;
