import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 0.3rem;
`;
const LogoImageContainer = styled.div`
  /* height: 1.6rem; */
  margin: 0;
  padding: 0;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    flex-grow: 1;
    /* height: 1.6rem; */
  }
`;

const Logo = ({ color, style }) => (
  <LogoContainer style={style}>
    <Link to="/">
      <LogoImageContainer>
        {/* <Icon type={IconType.NavLogo} /> */}

        <svg width="100%" height="100%" viewBox="0 0 807 217" version="1.1">
          <g transform="matrix(1,0,0,1,-356,-345)">
            <g>
              <g transform="matrix(1,0,0,1,131.484,76.8435)">
                <g transform="matrix(360,0,0,360,211.934,481.2)">
                  <path
                    d="M0.037,-0.474l0.141,0l0.118,0.338l0.119,-0.338l0.14,0l-0.186,0.474l-0.145,-0l-0.187,-0.474Z"
                    fill={color}
                  />
                </g>
                <g transform="matrix(325.432,0,0,325.432,400.214,481.2)">
                  <path
                    d="M0.304,0.01c-0.084,0 -0.15,-0.021 -0.197,-0.063c-0.046,-0.041 -0.07,-0.103 -0.07,-0.185c0,-0.077 0.021,-0.138 0.062,-0.181c0.041,-0.043 0.1,-0.065 0.179,-0.065c0.072,0 0.128,0.019 0.168,0.056c0.039,0.038 0.059,0.089 0.059,0.152l0,0.086l-0.343,0c0.007,0.037 0.024,0.062 0.051,0.077c0.027,0.015 0.066,0.022 0.119,0.022c0.025,-0 0.051,-0.002 0.078,-0.007c0.026,-0.005 0.048,-0.011 0.067,-0.018l0,0.096c-0.044,0.02 -0.102,0.03 -0.173,0.03Zm0.083,-0.283l0,-0.026c0,-0.059 -0.035,-0.088 -0.105,-0.088c-0.044,0 -0.075,0.009 -0.093,0.027c-0.018,0.017 -0.027,0.046 -0.027,0.087l0.225,-0Z"
                    fill={color}
                  />
                </g>
                <g transform="matrix(325.432,0,0,325.432,566.835,481.2)">
                  <path
                    d="M0.057,-0.474l0.125,0l0.006,0.049c0.022,-0.014 0.049,-0.026 0.08,-0.037c0.031,-0.011 0.062,-0.018 0.093,-0.022l0,0.102c-0.029,0.004 -0.059,0.011 -0.092,0.021c-0.033,0.009 -0.058,0.019 -0.077,0.03l0,0.331l-0.135,-0l0,-0.474Z"
                    fill={color}
                  />
                </g>
                <g transform="matrix(325.432,0,0,325.432,678.459,481.2)">
                  <path
                    d="M0.255,0.01c-0.067,0 -0.121,-0.019 -0.16,-0.056c-0.039,-0.037 -0.058,-0.096 -0.058,-0.177c0,-0.055 0.01,-0.102 0.031,-0.141c0.02,-0.039 0.047,-0.069 0.081,-0.09c0.035,-0.02 0.073,-0.03 0.116,-0.03c0.052,0 0.096,0.012 0.133,0.036l0,-0.204l0.135,0l0,0.652l-0.116,-0l-0.008,-0.044c-0.021,0.018 -0.043,0.031 -0.068,0.04c-0.025,0.009 -0.053,0.014 -0.086,0.014Zm0.037,-0.099c0.022,-0 0.041,-0.003 0.058,-0.01c0.017,-0.007 0.033,-0.018 0.048,-0.033l0,-0.216c-0.028,-0.025 -0.061,-0.037 -0.098,-0.037c-0.041,-0 -0.073,0.014 -0.094,0.041c-0.021,0.027 -0.032,0.068 -0.032,0.121c0,0.049 0.01,0.083 0.03,0.103c0.019,0.021 0.049,0.031 0.088,0.031Z"
                    fill={color}
                  />
                </g>
                <g transform="matrix(325.432,0,0,325.432,857.447,481.2)">
                  <path
                    d="M0.057,-0.474l0.125,0l0.005,0.045c0.021,-0.016 0.047,-0.029 0.076,-0.039c0.029,-0.011 0.06,-0.016 0.091,-0.016c0.062,0 0.108,0.015 0.137,0.044c0.028,0.029 0.043,0.074 0.043,0.136l0,0.304l-0.135,0l0,-0.297c0,-0.033 -0.007,-0.055 -0.02,-0.068c-0.013,-0.013 -0.037,-0.02 -0.072,-0.02c-0.021,0 -0.041,0.005 -0.062,0.014c-0.021,0.009 -0.038,0.02 -0.053,0.035l0,0.336l-0.135,0l0,-0.474Z"
                    fill={color}
                  />
                </g>
              </g>
              <g transform="matrix(0.923727,0,0,1.88388,149.549,-147.346)">
                <rect
                  x="224.298"
                  y="261.859"
                  width="203.897"
                  height="9.46"
                  fill={`${color}aa`}
                />
              </g>
            </g>
          </g>
        </svg>
      </LogoImageContainer>
    </Link>
  </LogoContainer>
);

export default Logo;
