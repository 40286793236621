/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { PopupButton } from "@typeform/embed-react";

import Media from "../media";
import Header from "../header";
import Footer from "../footer";
import Wrapper from "./wrapper";
import HeaderBanner from "../header-banner";

import "./layout.css";

const MAX_WIDTH = 1080;

const Container = styled.div``;

const Section = styled.div`
  padding: 8rem 0;
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `};
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: ${backgroundImage};
    `};
`;

const Topper = styled.div`
  position: absolute;
  width: 100%;
  height: 20vh;
  top: -19.9vh;
  left: 0;
  background: white;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  z-index: 8;
`;

const CenteredText = styled.div`
  max-width: 40rem;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    max-width: none;
  }
`;

// const Emoji = styled.h2`
//   display: none;
// `;

const IconText = styled.span`
  border-radius: 20rem;
  font-size: ${({ small }) => (small ? 1.8 : 3)}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: ${({ small }) => (small ? 3 : 5)}rem;
  height: ${({ small }) => (small ? 3 : 5)}rem;
`;

const Emoji = ({
  children,
  color = "#438e79",
  backgroundColor = "#dcf3eb",
  small = false,
}) => (
  <h2>
    <IconText
      small={small}
      style={{ color, backgroundColor }}
      className="material-icons"
    >
      {children}
    </IconText>
  </h2>
);

const Caption = styled.p`
  font-weight: 600;
  font-size: 1rem;
  color: #555;
  border-left: 4px solid #005a5c;
  padding-left: 0.6rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

const HalvesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};

  & > div:first-child {
    margin-bottom: ${({ reverse }) => (reverse ? 0 : 2)}rem;
  }

  & > div:last-child {
    margin-bottom: ${({ reverse }) => (reverse ? 2 : 0)}rem;
  }

  ${Media.Laptop`
    flex-direction: row;
    
    & > div:first-child {
      margin-bottom: 0;
      margin-right: 2.5rem;
      flex: ${({ reverse }) => (reverse ? 1 : 0.6)};
    }

    & > div:last-child {
      margin-bottom: 0;
      margin-left: 2.5rem;
      flex: ${({ reverse }) => (reverse ? 0.6 : 1)};
    }
  `}
`;

const Halves = ({ children, reverse = false }) => (
  <HalvesContainer reverse={reverse}>{children}</HalvesContainer>
);

const Half = ({ style, children }) => <div style={style}>{children}</div>;

const ContextButtonStyle = styled.a`
  /* padding: 0 0.2rem; */
  /* background: ${({ plain }) => (plain ? "#DCF3EB" : "#428e79")};
  color: ${({ plain }) => (plain ? "#005A5C" : "#fff")}; */
  color: #428e79;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-start; */
  border-radius: 0.2rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  font-style: normal;

  transition: all 0.2s;

  &:hover {
    /* background: ${({ plain }) => (plain ? "#eff8f5" : "#539c87")}; */
    color: #539c87;
  }

  span {
    font-size: inherit;
    margin-left: 0.2rem;
  }
`;

const ContextButton = ({ children, formId }) => (
  <PopupButton
    id={formId}
    style={{ border: "none", background: "none", padding: 0, margin: 0 }}
  >
    <ContextButtonStyle>
      {children}
      <span className="material-icons">chat</span>
    </ContextButtonStyle>
  </PopupButton>
);

const Layout = ({ children, navFill }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <HeaderBanner
        bannerKey="ZAPIER_LAUNCH"
        message={
          <span>
            Get early access to our no-code Zapier integration! ⭐️ Sign up
            today to get $10 in free credit&nbsp;&rsaquo;
          </span>
        }
        url="/#section_get_started"
        enableCloseButton={false}
        isExternal={false}
      />
      <Header fill={navFill} siteTitle={data.site.siteMetadata.title} />
      <main>
        <Container>{children}</Container>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export {
  Wrapper,
  Section,
  Topper,
  CenteredText,
  Emoji,
  Caption,
  Halves,
  Half,
  ContextButton,
  MAX_WIDTH,
};
