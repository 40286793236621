import { useEffect } from "react";
import { ready, identify } from "../utils/analytics";

const Embeds = () => {
  useEffect(() => {
    /** START HubSpot. */
    function initHubspot() {
      window.HubSpotConversations.on('conversationStarted', () => {
        analytics.track("Landing Page Sales Intent Interaction", {
          label: "HubSpot Chat conversation started"
        });
      });
    }
    if (window.HubSpotConversations) {
      initHubspot();
    } else {
      window.hsConversationsOnReady = [initHubspot];
    }
    /** END HubSpot. */

    /** START Segment. */
    ready(() => {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get("uid");
      if (userId) {
        identify(userId);
      }
    });
    /** END Segment. */
  }, []);
  return null;
};

export default Embeds;
